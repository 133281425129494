
import React from 'react';

import '../../styles/footer/footer.css';

function Footer() {
  
    return (
        <div className="footer">
            by: julio cqc
        </div>
    )
}

export default Footer;
